// import React from "react";
// import css from "../Modulecss/Home.module.css";
// import { Link } from "react-router-dom";

// const boatGameAomunt = (Math.random() * 6 + 1) * 50;

// export default function RunningCard({
//   runnig,
//   user,
//   winnAmount,
//   game_type,
//   bot,
// }) {
//   //!bot&& console.log(runnig, "dd");
//   return (
//     <div className={`${css.betCard} battle_card_main mt-1`}>
//       <div className="d-flex">
//         <span
//           className={`${css.betCardTitle} pl-3 d-flex align-items-center text-uppercase headings`}
//         >
//           PLAYING FOR
//           <img
//             className="mx-1"
//             src={
//               process.env.PUBLIC_URL +
//               "/images/LandingPage_img/global-rupeeIcon.png"
//             }
//             alt=""
//             width="21px"
//           />
//           {bot ? boatGameAomunt : runnig.Game_Ammount}
//         </span>

//         {!bot &&
//           (user == runnig.Accepetd_By._id || user == runnig.Created_by._id) && (
//             <Link
//               className={`${css.bgSecondary} ${css.playButton} ${
//                 css.cxy
//               } position-relative m-2 mx-1 ${
//                 runnig.Status == "conflict" ? "bg-danger" : "bg-success"
//               }`}
//               style={{
//                 right: "0px",
//                 top: "-6px",
//                 padding: "10px 17px",
//               }}
//               to={{
//                 pathname: `/viewgame1/${runnig._id}`,
//                 state: { prevPath: window.location.pathname },
//               }}
//             >
//               view
//             </Link>
//           )}
//         <div
//           className={`${css.betCardTitle} d-flex align-items-center text-uppercase`}
//         >
//           <span className="ml-auto mr-3 headings">
//             PRIZE
//             <img
//               className="mx-1"
//               src="/images/global-rupeeIcon.png"
//               alt=""
//               width="21px"
//             />
//             {bot
//               ? parseInt(boatGameAomunt * 2 - (boatGameAomunt * 2 * 5) / 100)
//               : runnig.Game_Ammount + winnAmount(runnig.Game_Ammount)}
//           </span>
//         </div>
//       </div>
//       <div className="py-1 row">
//         <div className="pr-3 text-center col-5">
//           <div className="pl-2">
//             {!bot && runnig.Created_by.avatar ? (
//               <img
//                 src={`https://backend.Ludo07.com/${
//                   runnig.Created_by && runnig.Created_by.avatar
//                 }`}
//                 onError={(e) => {
//                   e.target.onerror = null;
//                   e.target.src = "https://backend.Ludo07.com/user.png";
//                 }}
//                 alt=""
//                 width="30px"
//                 height="30px"
//                 style={{
//                   borderTopLeftRadius: "50%",
//                   borderTopRightRadius: "50%",
//                   borderBottomRightRadius: "50%",
//                   borderBottomLeftRadius: "50%",
//                 }}
//               />
//             ) : (
//               <img
//                 src="/images/avatars/Avatar2.png"
//                 width="30px"
//                 height="30px"
//                 alt="user"
//                 style={{ borderRadius: "50%" }}
//               />
//             )}
//           </div>
//           <div style={{ lineHeight: 1 }}>
//             <span className={`${css.betCard_playerName} headings`}>
//               {bot
//                 ? Math.random().toString(36).substring(2, 5).toUpperCase()
//                 : runnig.Created_by.Name}
//             </span>
//           </div>
//         </div>
//         <div className="pr-3 text-center col-2 cxy">
//           <div>
//             <img src="/Images/Homepage/versus.png" alt="" width="21px" />
//           </div>
//         </div>
//         <div className="text-center col-5">
//           <div className="pl-2">
//             {!bot && runnig.Accepetd_By.avatar ? (
//               <img
//                 src={`https://backend.Ludo07.com/${
//                   runnig.Accepetd_By && runnig.Accepetd_By.avatar
//                 }`}
//                 onError={(e) => {
//                   e.target.onerror = null;
//                   e.target.src = "https://backend.Ludo07.com/user.png";
//                 }}
//                 alt=""
//                 width="30px"
//                 height="30px"
//                 style={{
//                   borderTopLeftRadius: "50%",
//                   borderTopRightRadius: "50%",
//                   borderBottomRightRadius: "50%",
//                   borderBottomLeftRadius: "50%",
//                 }}
//               />
//             ) : (
//               <img
//                 src="/images/avatars/Avatar3.png"
//                 width="30px"
//                 height="30px"
//                 alt="user"
//                 style={{ borderRadius: "50%" }}
//               />
//             )}
//           </div>
//           <div style={{ lineHeight: 1 }}>
//             <span className={`${css.betCard_playerName} headings`}>
//               {bot
//                 ? Math.random().toString(36).substring(2, 5).toUpperCase()
//                 : runnig.Accepetd_By.Name}
//             </span>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

import React, { useMemo } from "react";
import css from "../Modulecss/Home.module.css";
import { Link } from "react-router-dom";

export default function RunningCard({ runnig, user, winnAmount, bot }) {
  const boatGameAmount = useMemo(
    () => parseInt(Math.random() * 6 + 1) * 50,
    []
  );

  const {
    _id: runningId,
    Game_Ammount: gameAmount,
    Status: status,
    Accepetd_By: acceptedBy,
    Created_by: createdBy,
  } = runnig;

  const isUserInGame = user === acceptedBy?._id || user === createdBy?._id;
  const prizeAmount = bot
    ? parseInt(boatGameAmount * 2 - (boatGameAmount * 2 * 5) / 100)
    : gameAmount + winnAmount(gameAmount);

  return (
    <div className={`${css.betCard} battle_card_main mt-1`}>
      <div className="d-flex">
        <span
          className={`${css.betCardTitle} pl-3 d-flex align-items-center text-uppercase headings`}
        >
          PLAYING FOR
          <img
            className="mx-1"
            src={`${process.env.PUBLIC_URL}/images/LandingPage_img/global-rupeeIcon.png`}
            alt=""
            width="21px"
          />
          {bot ? boatGameAmount : gameAmount}
        </span>

        {!bot && isUserInGame && (
          <Link
            className={`${css.bgSecondary} ${css.playButton} ${
              css.cxy
            } position-relative m-2 mx-1 ${
              status === "conflict" ? "bg-danger" : "bg-success"
            }`}
            style={{ right: "0px", top: "-6px", padding: "10px 17px" }}
            to={{
              pathname: `/viewgame1/${runningId}`,
              state: { prevPath: window.location.pathname },
            }}
          >
            view
          </Link>
        )}

        <div
          className={`${css.betCardTitle} d-flex align-items-center text-uppercase`}
        >
          <span className="ml-auto mr-3 headings">
            PRIZE
            <img
              className="mx-1"
              src="/images/global-rupeeIcon.png"
              alt=""
              width="21px"
            />
            {prizeAmount}
          </span>
        </div>
      </div>

      <div className="py-1 row">
        {renderPlayerInfo(createdBy, bot)}
        <div className="pr-3 text-center col-2 cxy">
          <img src="/Images/Homepage/versus.png" alt="" width="21px" />
        </div>
        {renderPlayerInfo(acceptedBy, bot)}
      </div>
    </div>
  );

  function renderPlayerInfo(player, isBot) {
    const avatarSrc = player?.avatar
      ? `https://backend.Ludo07.com/${player.avatar}`
      : `/images/avatars/Avatar${
          isBot ? (Math.random() < 0.5 ? 2 : 3) : 2
        }.png`;

    return (
      <div className="pr-3 text-center col-5">
        {/* <div className="pl-2">
          <img
            src={avatarSrc}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "https://backend.Ludo07.com/user.png";
            }}
            alt="user"
            width="30px"
            height="30px"
            style={{ borderRadius: "50%" }}
          />
        </div> */}
        <div style={{ lineHeight: 1 }}>
          <span className={`${css.betCard_playerName} headings`}>
            {isBot
              ? Math.random().toString(36).substring(2, 5).toUpperCase()
              : player?.Name}
          </span>
        </div>
      </div>
    );
  }
}
